body {
  /* overflow-x: hidden; */
}
.web-container {
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  min-width: 960px;
  max-width: 1440px;

}
.anchorBL {
  display: none;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* *, *::before, *::after {
  transition: none!important;
} */
.min-container {
  min-height: 70vh
}

.h3 {
  height: 40px;
  font-size: 28px;
  font-weight: 500;
  color: #3C3C3C;
  line-height: 40px;
}

.h4 {
  font-size: 24px;
  font-weight: 500;
  color: #3C3C3C;
  line-height: 40px;
}

.h5 {
  height: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #3C3C3C;
  line-height: 25px;
}

.h6 {
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

.normal-text {
  font-size: 16px;
  font-weight: 400;
  color: #3C3C3C;
  line-height: 26px;
}
.normal-link {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #197DFF;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
}
.normal-link:hover {
  text-decoration: underline;
  opacity: 0.9;
}
.normal-link:focus {
  text-decoration: underline;
} 
.gray-text {
font-size: 14px;
font-weight: 400;
color: #868686;
line-height: 20px;
}
.bold-text {
  font-weight: 500;
}
.ant-affix {
  z-index: 5;
}

.mini-text {
  font-size: 14px;
}
.mini-text .ellipsis-text {
  word-break: break-all;
  max-width: 160px;
  display: inline-block;
}

.blue-text {
  color: #197DFF
}

.custom-menu {
  display: flex;
  justify-content: center;
  border-width: 0;
}

.ant-menu {
  font-size: 16px;
  color: #555555;
}

.ant-menu-light>.ant-menu {
  color: #555555;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-inline: 16px;
  font-size: 16px;
  color: #555555;
}
.description-text {
  font-size: 14px;
  color: rgb(85, 85, 85);
  line-height: 20px;
  -webkit-line-clamp: 2;
  margin-bottom: 4px !important;
}
.time-text {
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 26px;
  margin-bottom: 4px;
}
.author-text {
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 26px;
  margin-right: 6px;
  margin-bottom: 4px;
  max-width: calc(100% - 150px) !important;
}
.custom-devider {
  height: 3px;
  width: 100%;
  background: #EBEEF5;
  border-radius: 10px;
  margin: 10px 0;
}
.base-card {
  padding: 14px 32px 22px;
  border-radius: 20px;
  overflow: hidden;
}

.article-container {
  padding: 28px 32px;
}
.article-container * {
  max-width: 100%;
  white-space: pre-wrap!important;
  word-break: break-word;
}
.article-container img {
  max-width: 100%;
}

@media screen and (max-width: 1600px) and (min-width: 0px) {
  .ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
    padding: 0 16px
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
    padding: 0 24px
  }
}

@media screen and (min-width: 1920px) {
  .ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
    padding: 0 40px
  }
}

.custom-menu .ant-menu-overflow-item {
  height: 60px;
  line-height: 60px;
}

.custom-menu .ant-menu-overflow-item:hover {
  color: #5CAB12
}

.custom-menu .ant-menu-overflow-item:hover .ant-menu-title-content,
.custom-menu .ant-menu-overflow-item:hover .ant-menu-title-content a,
.custom-menu .ant-menu-overflow-item .ant-menu-title-content a:hover,
.custom-menu .ant-menu-item-selected .ant-menu-title-content,
.custom-menu .ant-menu-submenu-selected .ant-menu-title-content {
  color: #5CAB12;
  font-weight: 500
}

.custom-menu .ant-menu-submenu:after,
.custom-menu .ant-menu-overflow-item:after,
.custom-menu .ant-menu-overflow-item.ant-menu-submenu-open:after {
  border-width: 0 !important;
}

/* .custom-menu .ant-menu-overflow-item.ant-menu-item-selected:after, .custom-menu .ant-menu-overflow-item.ant-menu-submenu-selected:after {
  border-width:0  !important;
  height: 5px;
  background: linear-gradient(8deg, #DDEC9F 0%, #29720E 100%);
  box-shadow: 0px 1px 2px 0px rgba(218,218,218,0.5);
  border-radius: 3px;

} */
.header-wrapper {
  z-index: 9999999;
}
.header-wrapper .ant-menu.ant-menu-submenu .ant-menu-item-selected {
  background: #fff;
}
.description-container {
  background: #F9F9F9;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #555555;
  line-height: 20px;
  padding: 16px 24px;
  margin-bottom: 6px;
}

.custom-banner .slick-dots {
  justify-content: flex-end;
  padding-right: 10px;
  bottom: 28px
}

.custom-banner .slick-dots li {
  width: 8px !important;
}

.custom-banner .slick-dots li button {
  height: 5px;
  width: 5px;
  border-radius: 5px;
}

.sub-header-container .slick-dots {
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 16vw;
}

.sub-header-container .slick-dots li {
  width: 8px !important;
}

.sub-header-container .slick-dots li button {
  height: 5px;
  background: #A2B8AE;
  width: 5px;
  border-radius: 5px;
}

.sub-header-container .slick-dots li.slick-active button {
  background: #A6CBAA;
}

.custom-card .base-info {
  border-bottom: 1px solid #EBEEF5
}

.custom-card .base-info:last-of-type {
  border-width: 0;
}

.web-link,
.footer-text {
  font-size: 12px;
}
.web-link {
  padding-bottom: 4px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
}

.web-link:hover {
  color: rgba(255, 255, 255, 1) !important;
  text-decoration: underline !important;
}

.text-color {
  color: #3C3C3C
}

.branch-wrapper {
  width: 240px;
  padding-left: 50px;

}

.branch-main-title {
  height: 21px;
  font-size: 15px;
  font-weight: 500;
  color: #3C3C3C;
  line-height: 21px;
  margin-bottom: 22px;
  padding-left: 18px;
  position: relative;

}

.branch-main-title:before {
  content: '';
  display: inline-block;
  width: 9px;
  height: 9px;
  left: 0;
  top: 4px;
  background: linear-gradient(126deg, #DDEC9F 0%, #29720E 100%);
  position: absolute;
  z-index: 1;
  margin-right: 10px;
}

.branch-sub-title {
  font-size: 12px;
  font-weight: 400;
  color: #3C3C3C;
  line-height: 17px;
  margin-left: 16px;
  margin-bottom: 22px;
  padding-left: 18px;
  position: relative;
}

.branch-sub-title:before {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  left: 0;
  top: 4px;
  background: linear-gradient(130deg, #BED1AD 0%, #9EB88D 100%);
  margin-right: 10px;
  position: absolute;
  z-index: 1;
}

.branch-sub-title:after {
  content: '';
  width: 16px;
  height: 39px;
  position: absolute;
  left: -12px;
  top: -30px;
  display: inline-block;
  border-left: 1px solid #C1D0B4;
  border-bottom: 1px solid #C1D0B4;
}

.service-tab-list {
  width: 100%;
  justify-content: space-between;

}

.service-tab-list .service-tab-item {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  height: 116px;
  background: #FFFFFF;
  border-radius: 10px;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.service-tab-list .service-tab-item .service-tab-img {
  margin-bottom: 8px;
}

.service-tab-list .service-tab-item:last-child {
  margin-right: 0;
}

.service-desc-list {
  margin-top: 24px;
}

.service-desc-list .service-desc-item {
  text-indent: 32px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-24 {
  margin-left: 24px;
}

.margin-right-24 {
  margin-right: 24px;
}

.border-radius-10 {
  border-radius: 10px;
}

.footer-wrapper {
  background-color: #578035;
  color: #F6F6F6;
  padding-top: 24px;
}

.footer-wrapper .footer-text {
  color: #F6F6F6;
}

.breadcrumb-btn {
  cursor: pointer;
}

.breadcrumb-btn:hover {
  color: #197DFF
}

.footer-wrapper .footer-bottom {
  background-color: #83AF5E;
  margin-top: 24px;
  color: #3C5E1F
}

.base-info-title:hover {
  color: #197DFF !important
}

.select-tag {
  width: 72px;
  height: 32px;
  background: #5CAB12;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  margin-right: 16px;
  cursor: pointer;
}

.default-tag {
  width: 72px;
  height: 32px;
  background: #FCFDFF;
  border-radius: 3px;
  border: 1px solid #EBEEF5;
  font-size: 14px;
  font-weight: 500;
  color: #868686;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  margin-right: 16px;
  cursor: pointer;
}

.phone-icon {
  transform: rotate(90deg);
}
.header-search-icon {
  width: 32px;
  height: 32px;
  background: #5CAB12;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 32px;
  box-sizing: border-box;
  padding-left: 1px;
  transform: scale(0.7);
  transform-origin: center center;
}
.search-container {
  display: flex;
  justify-content: center;
  position: relative;
}
.search-page-container {
  padding: 32px;
  background: #fff;
  position: sticky;
  width: 100%;
  top: 60px;
}
.search-web-container {
  /* padding-top: 120px */
}
.search-wrapper {
  width: 800px;
  height: 54px;
  background: #F8F8F8;
  border-radius: 10px;
  position: relative;
}
.search-wrapper .ant-input {
  background: #F8F8F8;
  padding-right: 100px;
  border-color: #F8F8F8;
  /* border-top-left-radius: 10px;

  border-bottom-left-radius: 10px; */
  border-radius: 10px !important;
}
.search-wrapper .search-icon {
  width: 80px;
  height: 54px;
  background: #5CAB12;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 222;
}
.search-close-icon {
  position: absolute;
  right: 20px;
  height: 54px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.apply-info-item {
  display: flex;
  padding: 21px 16px;
  background: #F8F8F8;
  border-radius: 10px;
}
.ellipsis {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
}
.web-container table {
  border-width: 1px;
  border-color: #2c2c2c;
}
.web-container table tbody{
  border-color: inherit;
 }
.web-container table tr {
  border-width: 1px;
  border-color: inherit;
}
.web-container table td {
  border-width: 1px;
  border-color: inherit;
}

.not-container {
  height: calc(100vh - 90px)
}
.tabbar-sub-list {
  position: relative;
  
}
.tabbar-sub-list:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #578035;
    top: -8px;
    display: block;
    position: absolute;
    left: 50px;
}


.side-bar-container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  outline: 0;
}
.side-bar-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.side-bar-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(117, 117, 117, 0);
  background: rgba(121, 121, 121, 0);
}

.side-bar-container::-webkit-scrollbar-thumb {
  width: 6px;
  background: rgba(128, 128, 128, 0.4);
  border-radius: 5px;
}

.side-bar-container::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.6);
}
